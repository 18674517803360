@import 'assets/scss/fonts.scss';
@import 'assets/scss/variables';
@import 'assets/scss/overrides';

@import '~slick-carousel/slick/slick';
@import '~slick-carousel/slick/slick-theme';

@import 'assets/scss/dark.scss';

html {
  transition: color 0.5s ease, background-color 0.5s ease;
}

body {
  font-family: 'Poppins';
}

.App {
  font-weight: $font-weight-normal;
  background-color: $background-blue;
  padding-bottom: 2.5rem;
  min-height: calc(100vh - 70px - 83px);
}

.alert-info {
  color: #6283ba;
  background-color: #e3eeff;
  border-color: #6283ba;
}

.alert-danger {
  color: $red;
  background-color: #fff9f9;
  border-color: $red;
}

.slick-slide > div {
  margin: 0 10px;
}
.slick-list {
  margin: 0 -10px;
}

.slick-prev,
.slick-next {
  width: 0;
  height: 0;
  top: 40%;
  z-index: 1;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}

.slick-prev {
  border-right: 25px solid #6283ba;
  left: -12px;
}

.slick-prev.slick-disabled,
.slick-next.slick-disabled {
  border: none;
}

.slick-next {
  border-left: 25px solid #6283ba;
  right: -12px;
}

.slick-prev::before,
.slick-next::before {
  content: none;
}

.section-gray {
  background-color: $blue-10;
  border: none;
  border-radius: 10px;
  margin-top: 20px;
}

.text-sm {
  font-size: 12px;
}

.text-md {
  font-size: 14px;
}

.simple-card {
  border-radius: 8px;
  border: 1px solid #d0daea;
  padding: 10px;
}

.btn-table {
  position: absolute;
  top: 4px;
  right: 4px;
}

.text-ellipsis {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100px;
}

.alert.alert-success {
  font-size: 14px;
  padding: 0.75rem 1.25rem;
}

.alert-dismissible .btn-close {
  padding: 1rem;
  box-shadow: none;
  outline: none;
}

@include color-mode(dark) {
  .App {
    background-color: #1b191b;
  }

  .toggle-label {
    background-color: #212529;
  }

  .main .tab-content {
    background-color: #212529;
  }

  .menu-bar .menu-items .nav-item a .card {
    border-bottom: 2px solid #1c191a;
  }
  .alert.alert-success {
    font-size: 14px;
    padding: 0.75rem 1.25rem;
  }

  .alert-dismissible .btn-close {
    padding: 1rem;
    box-shadow: none;
    outline: none;
  }
}

.expanded-row {
  background-color: #d0daea;
}

.tabs-section {
  .tab-content {
    padding: $spacing-large !important;
    border: 1px solid $gray-80;
    border-top: 1px solid transparent;
    border-radius: 0px !important;
  }

  .nav-tabs {
    padding-top: $spacing-medium;
    border-bottom: 1px solid $gray-80;
    li {
      margin-bottom: 0px;

      .tab-item {
        color: $black-85;
        padding: $spacing-small $spacing-large;

        &.active {
          color: $blue-200;
          font-weight: $font-weight-bold;
          border-color: $gray-80;
          border-bottom: 1px solid transparent;
        }
      }
    }
  }
}

@include color-mode(dark) {
  .App {
    background-color: #1b191b;
  }

  .top-nav {
    background-color: #212529;
  }

  .toggle-label {
    background-color: #212529;
  }

  .main .tab-content {
    background-color: #212529;
  }

  .menu-bar .menu-items .nav-item a .card {
    border-bottom: 2px solid #1c191a;
  }

  .table .green {
    color: #1b191b;
  }

  .space-section {
    background-color: #1c191a;
  }

  .pagination-controls {
    button {
      background-color: #212529;
    }
  }

  .expanded-row {
    & > td {
      background-color: #1c191a;
    }
  }

  .semi-circular-progressbar {
    circle {
      stroke: #6a6a6a;
    }
    .dot::before {
      border-color: #8d8d8d;
    }

    .circled-content {
      background-color: #6a6a6a;
    }
  }

  .circular-progressbar {
    circle {
      stroke: #6a6a6a;
    }
    .content {
      background-color: #6a6a6a;
    }
  }

  #budgets .card.card-budget {
    border: 2px solid #1c191a;
  }

  table {
    .current-month {
      background-color: #000711c2 !important;
    }
  }

  .tabs-section {
    .tab-content {
      border: 1px solid $blue-200;
      border-top: 1px solid transparent;
    }

    .nav-tabs {
      border-color: $blue-200;
      li {
        margin-bottom: 0px;

        .tab-item {
          color: #adb5bd;

          &.active {
            color: $blue-200;
            border-color: $blue-200;
            border-bottom: 1px solid transparent;
          }
        }
      }
    }
  }

  .select__control {
    background-color: #212529;
    border: 1px solid #495057;
    font-size: 13px;

    &:hover {
      border-color: #495057;
    }

    .select__multi-value {
      background-color: #3c3c3c;
      .select__multi-value__label {
        color: $gray-80;
      }
    }
  }

  .select__single-value {
    color: $gray-80;
  }

  .select__menu {
    background-color: #212529;
  }
  .select__option {
    background-color: #212529;
  }

  .select__option:hover {
    background-color: #3c3c3c;
  }

  .react-datepicker {
    background-color: #212529;
    border: 1px solid #495058;
  }

  .react-datepicker__header {
    background-color: #1b1b1b;
    border-bottom: 1px solid #1a1819;
    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
      color: #adb5bd;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      color: #adb5bd;
    }
  }

  .react-datepicker__triangle {
    &::before,
    &::after {
      border-bottom-color: #495058;
    }
  }

  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
    border-top: none;
    border-bottom-color: #1b1b1b;
  }

  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
    top: -1px;
    border-bottom-color: #495058;
  }

  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::after {
    border-bottom: none;
    border-top-color: #212529;
  }

  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before {
    bottom: -1px;
    border-top-color: #495058;
  }

  .react-datepicker__day--disabled {
    color: #585858 !important;
  }

  .react-datepicker__day {
    color: white;

    &:hover {
      background-color: #3c3c3c;
    }
  }
}
